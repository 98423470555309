//const scrollContainer = document.querySelector('.scroll-container');
// hasta no lo pueda solucionar
function scroll() {
    const firstChild = scrollContainer.firstElementChild;
    const scrollWidth = firstChild.offsetWidth;

    // Mueve el primer elemento al final
    scrollContainer.appendChild(firstChild.cloneNode(true));
    scrollContainer.scrollLeft += scrollWidth;

    // Elimina el primer elemento original después de un tiempo
    setTimeout(() => {
        scrollContainer.removeChild(scrollContainer.firstElementChild);
    }, 1000);
}

// Inicia el desplazamiento cada 300 ms (ajusta la velocidad según sea necesario)
setInterval(scroll, 1000);